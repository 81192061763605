import styled from '@emotion/styled'
import {
  Activity,
  Props as ActivityProps,
} from 'app/components/ActivityPreview/Activity'
import { IconSeparator } from 'app/components/Common/IconSeparator'
import { Mask } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  activities: ActivityProps[]
  title?: string
}

export const ActivityPreview = memo(function ActivityPreview({
  activities,
  title,
}: Props) {
  if (activities.length < 1) {
    return null
  }

  return (
    <Container>
      <IconSeparator className="separator" />

      {title ? <Title>{title}</Title> : null}

      <Wrapper>
        <Mask />
        <Activities row wrap>
          {activities.slice(0, 3).map((item, index) => (
            <Activity key={index} {...item} />
          ))}
        </Activities>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem auto 11rem;
  padding: 0 12.639vw;
  text-align: center;

  .separator {
    margin-bottom: 7.5rem;
  }

  @media (max-width: 1023px) {
    margin: 4.6875rem auto 5.5rem;
    padding: 0 6vw;

    .separator {
      margin-bottom: 2.5rem;
    }
  }

  @media (max-width: 767px) {
    padding: 0 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 36.875rem;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 3.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Wrapper = styled.div`
  position: relative;

  > svg {
    width: 100vw;
    height: auto;
    max-height: 70%;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.6;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(130%);
    z-index: -1;
  }
`

const Activities = styled(FlexBox)`
  margin: 2.5rem -1.6875rem 0 0;

  @media (max-width: 767px) {
    margin: 2.5rem auto;
  }
`
