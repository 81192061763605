import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  cta,
  subtitle,
  image,
  title,
}: Props) {
  return (
    <Container>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {cta ? <CTA variant="invert" {...cta} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 8.125rem 12.639vw 6.875rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding: 3.4375rem 1.25rem 4.6875rem;
  }
`

const Wrapper = styled.div`
  border: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-bottom: none;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 8.5rem 10.694vw 0;
  position: relative;
  text-align: center;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 30%;
    height: 0.125rem;
    background-color: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    position: absolute;
    bottom: 0;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  @media (max-width: 767px) {
    border: 0;
    padding: 3.75rem 0 3rem;
    &:before,
    &:after {
      display: none;
    }
  }
`

const Title = styled.h2`
  max-width: 48.75rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.375rem;
  margin: auto;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 700;
  font-size: 0.8125rem;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  margin-top: 5.625rem;
  transform: translateY(1.875rem);

  @media (max-width: 424px) {
    margin-top: 1.875rem;
  }
`
