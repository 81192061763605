import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  subtitle,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      <Separator className="separator" />
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}
      <Services row wrap>
        {services.slice(0, 4).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 5rem 0 0;
  text-align: center;

  .separator {
    position: absolute;
    top: -1.875rem;
    left: 50%;
  }

  @media (max-width: 1023px) {
    padding: 4rem 0 0;
  }

  @media (max-width: 767px) {
    padding-top: 2.25rem;
    .separator {
      display: none;
    }
  }
`

const Title = styled.h2`
  max-width: 40.625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.375rem;
  margin: auto;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
    padding: 0 1.25rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
  margin: 1.25rem auto 0;

  @media (max-width: 1023px) {
    padding: 0 1.25rem;
  }
`

const Services = styled(FlexBox)`
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`
