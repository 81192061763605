import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  subtitle?: string
  url: string
  image?: ImageProps
  title?: string
}

export const Activity = memo(function Activity({
  subtitle,
  url,
  image,
  title,
}: Props) {
  if (!url) {
    return null
  }

  return (
    <Container to={url}>
      <Background className="activity-bg">
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </Background>

      {title ? <Title>{title}</Title> : null}
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(33.333% - 1.6875rem);
  margin-right: 1.6875rem;
  text-align: center;
  &:hover {
    .activity-bg {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
      > div {
        &:after {
          height: 3.125rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.875rem;
    position: relative;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding-bottom: 162%;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 1;
  }
  &:before {
    height: 50%;
  }
  &:after {
    height: 100%;
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    padding-bottom: 100%;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  text-transform: uppercase;
  transform: translateX(-50%);
  z-index: 2;
  &:after {
    display: block;
    content: '';
    width: 0.125rem;
    height: 1.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 1.25rem auto 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    bottom: 4.375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 1.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin: 0 auto 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: 1.25rem;
    left: 0;
    z-index: 2;
    &:before {
      display: none;
    }
  }
`
