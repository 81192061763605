import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Facility = memo(function Facility({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container>
      {icon ? <Icon src={icon} alt={label} width="40" height="40" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled.div`
  width: 33.333%;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 3.125rem 2.5vw;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 3.5vw 2.5vw;
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }

  @media (max-width: 767px) {
    padding: 1.25rem 1.25rem;
    &:nth-of-type(2) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (max-width: 424px) {
    width: 100%;
    &:nth-of-type(2) {
      padding: 0 1.25rem;
    }
  }
`

const Icon = styled.img`
  display: block;
  width: auto;
  height: 2.5rem;
  margin: 0 auto 1rem;

  @media (max-width: 1023px) {
    height: 2rem;
    margin: 0 auto 0.75rem;
  }

  @media (max-width: 767px) {
    height: 1.75rem;
  }
`

const Label = styled.div`
  max-width: 8.125rem;
  margin: auto;

  @media (max-width: 424px) {
    max-width: none;
  }
`
