import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  subtitle?: string
  url?: string
  image?: ImageProps
  title?: string
}

export const Service = memo(function Service({
  subtitle,
  url,
  image,
  title,
}: Props) {
  return (
    <Container to={url || '#'}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {title ? (
        <Button className="service-button">
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {title ? <Title>{title}</Title> : null}
        </Button>
      ) : null}
    </Container>
  )
})

const Container = styled(Link)`
  width: 50%;
  padding-bottom: 45%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  text-align: left;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }

  &:hover {
    &:before {
      opacity: 0;
    }
    img {
      transform: scale(1.1);
    }
    .service-button {
      background-color: ${({ theme }) => theme.colors.variants.primaryDark2};
      border-color: ${({ theme }) => theme.colors.variants.primaryDark2};
    }
  }

  @media (max-width: 1023px) {
    &:hover {
      &:before {
        opacity: 0.3;
      }
      .service-button {
        background-color: transparent;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: all 0.3s ease-in-out !important;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 60%;
    margin-bottom: 0.625rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Button = styled.div`
  max-width: 18.75rem;
  border-right: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 4.3125rem 4.306vw 4.8125rem;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all ease-in-out 0.3s;
  z-index: 3;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: none;
    border: none;
    padding: 0 1.875rem;
    bottom: 1.875rem;
  }

  @media (max-width: 767px) {
    bottom: 1.25rem;
    padding: 0 1.25rem;
  }
`

const Subtitle = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1875rem;
  line-height: 1.125rem;
  margin-bottom: 0.875rem;
  text-transform: uppercase;
`

const Title = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
`
