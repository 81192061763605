import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Facility, Props as FacilityProps } from './Facility'

export interface Props {
  benefits: FacilityProps[]
  title?: string
}

export const Benefits = memo(function Benefits({ benefits, title }: Props) {
  return (
    <Container dial={5} row tag="section" stretch wrap>
      {title ? <Title dial={4}>{title}</Title> : null}

      <List dial={4} row space="between" wrap>
        {benefits.map((item, index) => (
          <Facility key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 0 auto;
`

const Title = styled(FlexBox)`
  width: 30%;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 4.25rem 10vw;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 3.5vw 3vw;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 1.875rem;
    text-align: center;
    display: block;
    line-height: 1.5rem;
  }
`

const List = styled(FlexBox)`
  width: 70%;
  padding: 0 13.889vw;

  @media (max-width: 1199px) {
    padding: 0 5vw;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0.75rem 0;
  }
`
