import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Separator } from 'app/components/Common/Separator'
import { Facebook } from 'app/components/Icons'
import { Instagram } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  facebookURL?: string
  images: ImageProps[]
  instagramURL?: string
}

export const SocialWall = memo(function SocialWall({
  facebookURL,
  images,
  instagramURL,
}: Props) {
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      <Separator className="separator" />

      <Hashtag>#hotelpuntanegra</Hashtag>

      <Social dial={5} row wrap>
        {facebookURL ? (
          <Anchor
            aria-label="Facebook"
            href={facebookURL}
            rel="noreferrer"
            target="_blank"
          >
            <Facebook />
          </Anchor>
        ) : null}
        {instagramURL ? (
          <Anchor
            aria-label="Instagram"
            href={instagramURL}
            rel="noreferrer"
            target="_blank"
          >
            <Instagram />
          </Anchor>
        ) : null}
      </Social>

      <Images dial={2} row wrap>
        {images.slice(0, 4).map((item, index) => (
          <Background key={index}>
            <LazyLoadComponent>
              <Image {...item} />
            </LazyLoadComponent>
          </Background>
        ))}
      </Images>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-top: 10.8125rem;
  padding-top: 4.125rem;
  position: relative;
  text-align: center;

  .separator {
    position: absolute;
    top: -1.875rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 2.25rem;
    .separator {
      display: none;
    }
  }
`

const Hashtag = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Social = styled(FlexBox)`
  margin-top: 2.0625rem;
`

const Anchor = styled.a`
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark4};
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    margin: 0 0.625rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark2};
    transition: 0.3s ease-in-out;
  }
`

const Images = styled(FlexBox)`
  margin-top: 2.625rem;

  @media (max-width: 767px) {
    margin-top: 1.875rem;
  }
`

const Background = styled.div`
  width: 25%;
  padding-bottom: 25%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`
